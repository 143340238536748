<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="list-item-two-line"
    :loading="activity.isFirstLoad || activity.isLoading"
  >
    <VRow column>
      <VCol style="flex: 1 1 auto">
        <template v-if="!activity.isLoading">
          <VExpansionPanels>
            <VExpansionPanel
              v-for="(item,key) in data.data"
              :key="key"
              cols="12"
              md="6"
            >
              <VExpansionPanelHeader
                class="px-4 pl-6"
                style="min-height: 64px"
              >
                <h3 
                  v-text="item.title" 
                  class="flex-grow-0"
                />
                <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, item.id)" />
              </VExpansionPanelHeader>
              <VExpansionPanelContent
                class="px-6 py-6 faq-content"
                v-html="item.content"
              />
            </VExpansionPanel>
          </VExpansionPanels>
          <VPagination
            v-if="canShowPagination"
            class="mt-3"
            :value="pagination.page"
            :length="pagination.pages"
            @input="page => onSearch({page})"
          />
        </template>
        <VRow
          v-else
          justify-center
          slot="progress"
          class="mt-4"
        >
          <VProgressCircular
            indeterminate
            color="primary"
          />
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import { mapActions } from "vuex";
export default {
  name: "FaqsList",
  mixins: [SearchMixin, HasStats],
  data() {
    return {
      date: null,
      modelType: "faq",
      config: {
        stats: {
          faqs: 0,
        },
      },
    };
  },
  computed: {
    headers() {
      let headers;
      headers = [
        {
          text: "Question",
          align: "left",
          sortable: true,
          value: "title",
          class: "faq-title",
        },
        {
          text: this.featureName("Answer"),
          align: "left",
          sortable: false,
          value: "content",
          class: "faq-content",
          width: "300px",
        },
      ];
      return headers;
    },
  },
  methods: {
    ...mapActions({
      doGetFaqs: "faq/getAll",
      doGetFAQ: "faq/getOne",
    }),
    onGet(id) {
      return this.doGetFAQ(id);
    },
    onDelete(faq) {
      return this.doDeleteFaq(faq.id);
    },
    onGetAll(params) {
      return this.doGetFaqs({
        ...params,
        ...{
          perPage: 20,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

#faq-list {
  .v-expansion-panel,
  .theme--light.v-expansion-panel .v-expansion-panel__container {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
  }

  .theme--light.v-expansion-panel .v-expansion-panel__header {
    border-radius: 100px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 #e8e8e8;
    margin-bottom: 20px;
  }

  .v-expansion-panel__header:hover,
  .v-expansion-panel__container--active .v-expansion-panel__header {
    color: inherit !important;
  }

  .v-expansion-panel__header__icon {
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .theme--light.v-expansion-panel
    .v-expansion-panel__container
    .v-expansion-panel__header
    .v-expansion-panel__header__icon
    .v-icon {
    color: $white-color;
  }

  .faq-content {
    background-color: transparent;
    color: #404041;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}
</style>