<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin || checkMentorPermission(modelType)"
      >
        <template slot="title">
          {{ featureName("FAQs") }}
        </template>
        <template #create-action>
          <ExpandableButton
            v-if="$isUserAdmin || checkMentorPermission(modelType)" 
            large
            button-class="wethrive px-5"
            button-style="display: grid;"
            icon="add"
            :to="{
              name: `${modelType}.create`,
              params: {
                team: $team.slug,
              },
            }"
            :tooltip-text="`Add ${featureName(modelType.capitalize(), 'singularize')}`"
          />
        </template>
        <template
          v-if="!$isUserApproved"
          #actions-right
        >
          <div
            :class="{
              fluid: $vuetify.breakpoint.smAndDown,
              'text-center': $vuetify.breakpoint.smAndDown,
            }"
          >
            <span>Having Application Questions?
              <VBtn
                depressed
                small
                text
                class="mx-2 mb-2"
                style="border: solid thin #E3B81A; color: black; background-color: #FFD63F;"
                @click="onToggleApplicantChat"
              >
                <VIcon
                  small
                  class="mr-1"
                >chat</VIcon>&nbsp;&nbsp;Contact us here
              </VBtn>
            </span>
            <br>
            <span>Having Technical Difficulties?
              <VBtn
                depressed
                small
                text
                class="mx-2 primary"
                @click="$bus.$emit('toggleIntercom')"
              >
                <VIcon
                  small
                  class="mr-1"
                >help</VIcon>&nbsp;&nbsp;Contact WeThrive
              </VBtn>
            </span>
          </div>
        </template>
      </IndexHeader>
    </VCol>
    <VCol style="flex: 1 1 auto">
      <Table v-if="$isUserAdmin || checkMentorPermission(modelType)" />
      <List v-else />
      <MessagesDialog
        max-width="640px"
        entity-type="pipeline-submitted"
        :is-dialog-open="dialogs.applicantChat"
        :title="`${((selectedPipeline || {}).thread_for_user || {}).title || 'Support'}`"
        :members="((selectedPipeline || {}).reviewers || []).map(u => u.id)"
        :entity="submittedApplication"
        :thread="chatThread"
        @toggle:dialog="onToggleApplicantChat"
        @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
      />
    </VCol>
  </VRow>
</template>

<script>
import List from "./List";
import Table from "./Table";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import MessagesDialog from "@/components/Chat/MessagesDialog";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";

export default {
  name: "FaqsIndex",
  mixins: [HasApplicationPipelinesMixin],
  components: {
    List,
    Table,
    IndexHeader,
    MessagesDialog
  },
  data() {
    return {
      modelType: "faq"
    };
  }
};
</script>

<style lang="scss">
@import "../../styles/stats.scss";

.data-table table.v-table thead th.column.faq-title {
  flex: 0 1 25%;
}

.data-table table.v-table thead th.column.faq-content {
  flex: 1 1 75%;
  max-width: 75%;
}
</style>