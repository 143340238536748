var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"column":""}},[_c('VCol',{staticStyle:{"flex":"0 0 64px"}},[_c('IndexHeader',{attrs:{"mode":"standalone","can-create":_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType)},scopedSlots:_vm._u([{key:"create-action",fn:function(){return [(_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType))?_c('ExpandableButton',{attrs:{"large":"","button-class":"wethrive px-5","button-style":"display: grid;","icon":"add","to":{
            name: `${_vm.modelType}.create`,
            params: {
              team: _vm.$team.slug,
            },
          },"tooltip-text":`Add ${_vm.featureName(_vm.modelType.capitalize(), 'singularize')}`}}):_vm._e()]},proxy:true},(!_vm.$isUserApproved)?{key:"actions-right",fn:function(){return [_c('div',{class:{
            fluid: _vm.$vuetify.breakpoint.smAndDown,
            'text-center': _vm.$vuetify.breakpoint.smAndDown,
          }},[_c('span',[_vm._v("Having Application Questions? "),_c('VBtn',{staticClass:"mx-2 mb-2",staticStyle:{"border":"solid thin #E3B81A","color":"black","background-color":"#FFD63F"},attrs:{"depressed":"","small":"","text":""},on:{"click":_vm.onToggleApplicantChat}},[_c('VIcon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("chat")]),_vm._v("  Contact us here ")],1)],1),_c('br'),_c('span',[_vm._v("Having Technical Difficulties? "),_c('VBtn',{staticClass:"mx-2 primary",attrs:{"depressed":"","small":"","text":""},on:{"click":function($event){return _vm.$bus.$emit('toggleIntercom')}}},[_c('VIcon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("help")]),_vm._v("  Contact WeThrive ")],1)],1)])]},proxy:true}:null],null,true)},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.featureName("FAQs"))+" ")])],2)],1),_c('VCol',{staticStyle:{"flex":"1 1 auto"}},[(_vm.$isUserAdmin || _vm.checkMentorPermission(_vm.modelType))?_c('Table'):_c('List'),_c('MessagesDialog',{attrs:{"max-width":"640px","entity-type":"pipeline-submitted","is-dialog-open":_vm.dialogs.applicantChat,"title":`${((_vm.selectedPipeline || {}).thread_for_user || {}).title || 'Support'}`,"members":((_vm.selectedPipeline || {}).reviewers || []).map(u => u.id),"entity":_vm.submittedApplication,"thread":_vm.chatThread},on:{"toggle:dialog":_vm.onToggleApplicantChat,"newThread":thread => _vm.$set(_vm.submittedApplication, 'thread_for_user', thread)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }